import React from 'react';

export const transition = {
  transition: 'all .1s',
} as React.CSSProperties;

export const expandAndLift = {
  transform: 'scale(1.05)',
  boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
  MoxBoxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
  WebkitBoxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
};
